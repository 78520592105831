// normalize CSS across browsers
import "./src/css/normalize.css"

// custom CSS styles
import "./src/css/global.scss"
import React from "react"
import { ShareDataContextProvider } from "./src/context/ShareDataContext"

export const wrapRootElement = ({ element }) => (
  <ShareDataContextProvider>{element}</ShareDataContextProvider>
)
