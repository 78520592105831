import React, { useState, createContext } from "react"

const innitialValue = {
  navHeight: "",
  setNavHeight: () => {},
}

export const ShareDataContext = createContext(innitialValue)

export function ShareDataContextProvider({ children }) {
  const [navHeight, setNavHeight] = useState("")
  return (
    <ShareDataContext.Provider value={{ navHeight, setNavHeight }}>
      {children}
    </ShareDataContext.Provider>
  )
}
